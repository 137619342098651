// apiService.ts
import { Configuration, DefaultApi, DefaultApiFactory } from './osakaserver_api';
import { AxiosRequestConfig, ResponseType } from 'axios';

export interface ServerVersion {
  serverVersion: string;
}

export class ApiService {
  private static instance: ApiService;
  private token: string | null = null;
  private baseUrl: string;
  private api: DefaultApi | null = null;

  private constructor() {
    this.baseUrl = this.getBackendBaseUrl();
  }

  public static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  public getBackendBaseUrl(): string {
    let backendBaseUrl = 'http://localhost:8008';
    if (process.env.NODE_ENV === 'production') {
      console.log('Running in production mode');
      backendBaseUrl = 'https://enrichedbooks.com';
    }
    else {
      console.log('Running in DEVELOPMENT mode');
    }
    return backendBaseUrl;
  }

  public getReaderBaseUrl(): string {
    let readerBaseUrl = 'http://localhost:8001';
    if (process.env.NODE_ENV === 'production') {
      console.log('Running in production mode');
      readerBaseUrl = 'https://enrichedbooks.com/reader';
    }
    else {
      console.log('Running in DEVELOPMENT mode');
    }
    return readerBaseUrl;
  }

  public getReaderEnrichmentAIUrl(bookId: string, enrichmentId: string): string {
    return `${this.getReaderBaseUrl()}/enrichmentai/${bookId}/${enrichmentId}`;
  }

  public setToken(token: string | null): void {
    this.token = token;
  }

  public async getApiConfig(responseType: ResponseType | null = null): Promise<{
    apiConfig: Configuration;
    apiParams: AxiosRequestConfig;
  }> {
    const token = this.token;
    const apiConfig = new Configuration({
      accessToken: token || '',
    });

    const apiParams: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (responseType) {
      apiParams.responseType = responseType;
    }

    return { apiConfig, apiParams };
  }

  public async getServerVersion(): Promise<string> {
    try {
      const { apiConfig, apiParams } = await this.getApiConfig();
      const api = DefaultApiFactory(apiConfig); // Get the API instance
      const version = await api.getVersion(apiParams);
      if (version.data) {
        return version.data.serverVersion;
      }
      else {
        return 'Unknown';
      }
    } catch (err) {
      console.error('Failed to fetch server version:', err);
      return `Unknown (error: ${err})`;
    }
  }
}

export const apiService = ApiService.getInstance();
