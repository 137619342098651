import React, { useState, useEffect, useRef } from 'react';
import './EnrichmentDialog.css';
import { apiService } from './ApiService';
import { AiEnrichmentDetails, AiModel, AiProvider, AuthorEnrichmentApiFactory, EnrichmentDetails, GetAiProvidersResponse, LLMRequest, LLMResponseUsage, UrlEnrichmentDetails } from './osakaserver_api';
import QueryArea from './QueryArea';
import { AxiosProgressEvent, RawAxiosRequestConfig } from 'axios';
import LLMChatWindow, { LLMChatWindowMethods } from './LLMChatWindow';

interface EnrichmentDialogProps {
  enrichmentBase: EnrichmentDetails;
  selectedText: string;
  onClose: (refreshEnrichments: boolean) => void;
}

const EnrichmentDialog: React.FC<EnrichmentDialogProps> = ({ enrichmentBase, selectedText, onClose }) => {
  const [enrichmentType, setEnrichmentType] = useState(enrichmentBase.type);
  const [enrichment, setEnrichment] = useState<AiEnrichmentDetails | UrlEnrichmentDetails | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<AiProvider | null>(null);
  const [selectedModel, setSelectedModel] = useState<AiModel | null>(null);
  const [initialQueryText, setInitialQueryText] = useState<string>('');
  const [predefinedQueries, setPredefinedQueries] = useState<string[]>([]);
  const [providers, setProviders] = useState<AiProvider[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [providerError, setProviderError] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [refreshEnrichments, setRefreshEnrichments] = useState<boolean>(false);
  const [hasBeenModified, setHasBeenModified] = useState<boolean>(false);
  const [isProcessingQuery, setIsProcessingQuery] = useState(false);

  const responseGroupRef = useRef<LLMChatWindowMethods>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const maxPredefinedQueries = 4;

  const fetchEnrichmentDetails = async (): Promise<AiEnrichmentDetails | UrlEnrichmentDetails> => {
    try {
      if (!enrichmentBase.id) {
        throw new Error('Enrichment ID is missing');
      }
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorEnrichmentApiFactory(apiConfig); // Get the API instance
      const response = await api.getEnrichmentDetails(enrichmentBase.book, enrichmentBase.id, apiParams);
      if (!response.data) {
        throw new Error(`HTTP error status: ${response.status}`);
      }    
      return await response.data;
    }
    catch (error) {
      console.error('Error fetching AI providers:', error);
      throw error;
    }
  };

  const fetchAiProviders = async (): Promise<GetAiProvidersResponse> => {
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = AuthorEnrichmentApiFactory(apiConfig); // Get the API instance
      const response = await api.getProviders(apiParams);
      if (!response.data) {
        throw new Error(`HTTP error status: ${response.status}`);
      }    
      return await response.data;
    }
    catch (error) {
      console.error('Error fetching AI providers:', error);
      throw error;
    }
  };

  const handleSaveCloseEnrichment = async () => {
    await handleApplyEnrichment();
    // Close the modal after saving the enrichment and refresh all enrichments
    onClose(refreshEnrichments);
  }

  const handleApplyEnrichment = async () => {
    if (!enrichment) {
      console.error('Enrichment details are missing');
      return;
    }

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorEnrichmentApiFactory(apiConfig);
    if (enrichmentType === 'ai') {
      const aiEnrichment = enrichment as AiEnrichmentDetails;
      aiEnrichment.initialQuery = initialQueryText;
      aiEnrichment.predefinedQueries = predefinedQueries;
      aiEnrichment.provider = selectedProvider?.id || '';
      aiEnrichment.aimodel = selectedModel?.id || '';

      if (enrichment.id) {
        await api.updateAiEnrichment(enrichment.id!, aiEnrichment, apiParams);
      }
      else {
        const response = await api.createAiEnrichment(aiEnrichment, apiParams);
        if (response && response.data) {
          enrichment.id = response.data;
        }
        else {
          console.error('Failed to create AI enrichment:', response);
        }
      }
    }
    else if (enrichmentType === 'url') {
      const urlEnrichment = enrichment as UrlEnrichmentDetails;
      urlEnrichment.url = url;

      if (enrichment.id) {
        await api.updateUrlEnrichment(enrichment.id!, urlEnrichment, apiParams);
      }
      else {
        const response = await api.createUrlEnrichment(urlEnrichment, apiParams);
        if (response && response.data) {
          enrichment.id = response.data;
        }
        else {
          console.error('Failed to create URL enrichment:', response);
        }
      }
    }

    setHasBeenModified(false);
    setRefreshEnrichments(true);
  };
  
  const handleCancelEnrichment = () => {
    // Close the modal without saving the enrichment
    onClose(refreshEnrichments);
  };

  const handleTestEnrichment = () => {
    if (!enrichment || !enrichment.id) {
      console.error('Enrichment ID is missing');
      return;
    }
    window.open(apiService.getReaderEnrichmentAIUrl(enrichment.book, enrichment.id), '_blank');
  }

  const handleAddPredefinedQuery = (query: string) => {
    if (predefinedQueries.length >= maxPredefinedQueries) {
      return;
    }
    setPredefinedQueries([...predefinedQueries, '']);
    setHasBeenModified(true);
  };

  const handleChangeQuery = (value: string, predefinedIndex?: number) => {
    if (predefinedIndex === undefined) {
      setInitialQueryText(value);
    }
    else {
      const newQueries = [...predefinedQueries];
      newQueries[predefinedIndex] = value;
      setPredefinedQueries(newQueries);
    }
    setHasBeenModified(true);
  };

  const handleDeletePredefinedQuery = (index: number) => {
    const newQueries = predefinedQueries.filter((_, i) => i !== index);
    setPredefinedQueries(newQueries);
    setHasBeenModified(true);
  };

  const handleTestQuery = async (predefinedIndex?: number) => {
    if (!responseGroupRef.current) return;
  
    let queryText: string;
    if (typeof predefinedIndex === 'number') {
      queryText = predefinedQueries[predefinedIndex];
    } else {
      queryText = initialQueryText;
    }
    
    if (queryText.trim()) {
      try {
        await responseGroupRef.current.runQuery(queryText);
      } catch (error) {
        console.error('Failed to run query:', error);
      }
    }
  };

  useEffect(() => {
    const initializeAi = async () => {
      setIsLoading(true);
      setProviderError('');
    
      try {
        const { providers } = await fetchAiProviders();
        setProviders(providers);
    
        if (enrichmentBase.id) {
          const fetchedEnrichment = await fetchEnrichmentDetails();
          if (!fetchedEnrichment || fetchedEnrichment.type !== 'ai') {
            throw new Error('Invalid enrichment details');
          }
          const aiEnrichment = fetchedEnrichment as AiEnrichmentDetails;
          setEnrichment(aiEnrichment);
          setInitialQueryText(aiEnrichment.initialQuery || '');
          setPredefinedQueries(aiEnrichment.predefinedQueries || []);
    
          const provider = providers.find(provider => provider.id === aiEnrichment.provider) || providers[0];
          const model = provider?.models.find(model => model.id === aiEnrichment.aimodel) || provider?.models.find(model => model.default) || provider?.models[0];
          setSelectedProvider(provider);
          setSelectedModel(model);
        }
        else {
          const defaultProvider = providers[0];
          const defaultModel = defaultProvider?.models.find(model => model.default) || defaultProvider?.models[0];
          
          setEnrichment({
            book: enrichmentBase.book,
            page: enrichmentBase.page,
            offset: enrichmentBase.offset,
            type: 'ai',
            provider: defaultProvider?.id || '',
            aimodel: defaultModel?.id || '',
            initialQuery: '',
            predefinedQueries: [],
          });
          
          setSelectedProvider(defaultProvider);
          setSelectedModel(defaultModel);
        }
      }
      catch (error) {
        setProviderError('Failed to load AI providers and models');
        console.error('Error loading providers and models:', error);
      }
      finally {
        setIsLoading(false);
      }
    };
    
    const initializeUrl = async () => {
      if (enrichmentBase.id) {
        const fetchedEnrichment = await fetchEnrichmentDetails();
        if (!fetchedEnrichment || fetchedEnrichment.type !== 'url') {
          throw new Error('Invalid enrichment details');
        }
        const urlEnrichment = fetchedEnrichment as UrlEnrichmentDetails;
        setEnrichment(urlEnrichment);
        setUrl(urlEnrichment.url);
      }
      else {
        setEnrichment({
          book: enrichmentBase.book,
          page: enrichmentBase.page,
          offset: enrichmentBase.offset,
          type: 'url',
          url: '',
        });
      }
    }

    if (enrichmentType === 'ai') {
      initializeAi();
    }
    else if (enrichmentType === 'url') {
      initializeUrl();
    }
  }, [enrichmentType]);

  // Reset model selection when provider changes
  useEffect(() => {
    setSelectedModel(selectedProvider?.models.find(model => model.default) || null);
  }, [selectedProvider]);

  const handleProcessingStateChange = (isProcessingQuery: boolean) => {
    console.log('Processing state changed:', isProcessingQuery); // Add this for debugging
    setIsProcessingQuery(isProcessingQuery);
  };
  
  return (
    <div className="edit-enrichment-modal-overlay">
      <div className="edit-enrichment-modal">
        <div className="modal-header">
          <h2>Configure Enrichment</h2>
          <select 
            className="enrichment-type-select"
            value={enrichmentBase.type} 
            onChange={(e) => {
              const type = e.target.value as 'ai' | 'url';
              setEnrichmentType(type);
              enrichmentBase.type = type; // Update the prop object if needed
            }}
          >
            <option value="ai">AI query</option>
            <option value="url">URL</option>
          </select>
        </div>

        {enrichmentType === 'ai' ? (
          <div className="modal-ai-content">
            <div className="modal-ai-set-content">
              <QueryArea
                initialQueryText={initialQueryText}
                predefinedQueries={predefinedQueries}
                onAddPredefinedQuery={handleAddPredefinedQuery}
                onChangeQuery={handleChangeQuery}
                onDetelePredefinedQuery={handleDeletePredefinedQuery}
                onTest={handleTestQuery}
                selectedPdfText={selectedText}
                isProcessingQuery={isProcessingQuery}
              />

              <div className="selection-container">
                <div className="providers-section">
                  <div className="provider-section-header">
                    <h3>Select AI Provider</h3>
                  </div>
                  {providerError ? (
                    <div className="error-message">{providerError}</div>
                  ) : (
                    <div className="provider-options">
                      {isLoading ? (
                        <div className="loading-indicator">Loading...</div>
                      ) : (
                        providers.map(provider => (
                          <label 
                            key={provider.id} 
                            className={`provider-option ${selectedProvider?.id === provider.id ? 'provider-option-selected' : ''}`}
                          >
                            <input
                              type="radio"
                              name="aiProvider"
                              value={provider.id}
                              checked={selectedProvider?.id === provider.id}
                              onChange={(e) => {
                                setSelectedProvider(providers?.find(provider => provider.id === e.target.value) || null);
                                (enrichment as AiEnrichmentDetails).provider = e.target.value;
                                setHasBeenModified(true);
                              }}
                            />
                            <span>{provider.name}</span>
                          </label>
                        ))
                      )}
                    </div>
                  )}
                </div>

                <div className="models-section">
                  <div className="model-section-header">
                    <h3>Select AI Model</h3>
                  </div>
                  <select
                    className="model-select"
                    value={selectedModel?.id}
                    onChange={(e) => {
                      setSelectedModel(selectedProvider?.models.find(model => model.id === e.target.value) || null);
                      (enrichment as AiEnrichmentDetails).aimodel = e.target.value;
                      setHasBeenModified(true);
                    }}
                    disabled={!selectedProvider || isLoading}
                  >
                    {selectedProvider ?
                      <></> :
                      <option value="">
                        Select model
                      </option>
                    }
                    {selectedProvider?.models.map(model => (
                      <option key={model.id} value={model.id}>{model.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-ai-test-content">
              <LLMChatWindow
                ref={responseGroupRef}
                provider={selectedProvider?.id || ''}
                model={selectedModel?.id || ''}
                onProcessingStateChange={handleProcessingStateChange}
              />
            </div>
          </div>
        ) : (enrichmentType === 'url' && (
          <div className="modal-url-content">
            <input
              type="text"
              placeholder="Enter URL..."
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                (enrichment as UrlEnrichmentDetails).url = e.target.value;
              }}
            />
          </div>
        ))}

        <div className="action-buttons">
          <button className="button cancel" onClick={handleCancelEnrichment}>{hasBeenModified ? 'Cancel' : 'Close'}</button>
          <button className="button saveclose" onClick={handleSaveCloseEnrichment}>Save & Close</button>
          <button className="button apply" onClick={handleApplyEnrichment} disabled={!hasBeenModified}>Apply</button>
          <button className="button test" onClick={handleTestEnrichment} disabled={hasBeenModified}>Test...</button>
        </div>
      </div>
    </div>
  );
};

export default EnrichmentDialog;