import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { getFrontendVersionString } from './version';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { QRCodeCanvas } from 'qrcode.react';
import QRCode from 'qrcode';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { auth } from './firebaseConfig';
import FirebaseAuth from './osakaFirebaseAuth';
import './App.css';
import EnrichmentDialog from './EnrichmentDialog';
import LoadingOverlay from './LoadingOverlay';
import { apiService } from './ApiService';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// Import the API function from the OpenAPI-generated code
import { AiEnrichmentDetails, AuthorBookApiFactory, AuthorEnrichmentApiFactory, BookDetails, Configuration, DefaultApiFactory, EnrichmentDetails, PartialBookDetails, UrlEnrichmentDetails } from './osakaserver_api'; // Adjust the path based on your project structure
import { ui } from './firebaseUiConfig';
import { AxiosRequestConfig, ResponseType } from 'axios';

const App: React.FC = () => {
  const [accessKey, setAccessKey] = useState<string>('');
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [serverVersion, setServerVersion] = useState<string | null>(null); // State for storing the server version
  const [user, setUser] = useState<User | null>(null);
  const [profilePicture, setProfilePicture] = useState<string | null>(null); // State for storing profile picture URL
  const [books, setBooks] = useState<BookDetails[]>([]); // State for books
  const [booksLoading, setBooksLoading] = useState<boolean>(false); // State for loading status
  const [error, setError] = useState<string | null>(null); // State for error
  const [showBookInfoModal, setShowBookInfoModal] = useState<boolean>(false); // Manage modal visibility
  const [showBookDeleteModal, setShowBookDeleteModal] = useState<boolean>(false); // Manage modal visibility
  const [modifyBook, setModifyBook] = useState<BookDetails | null>(null);
  const [currentBook, setCurrentBook] = useState<BookDetails | null>(null);
  const [bookContentsArray, setBookContentsArray] = useState<ArrayBuffer | null>(null);
  const [bookContents, setBookContents] = useState<Blob | null>(null);
  const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [numPages, setNumPages] = useState<number | null>(null);
  const [currentScale, setCurrentScale] = useState(1.0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pdfPageWidth, setPdfPageWidth] = useState<number | null>(null);
  const [showEnrichmentInfoModal, setShowEnrichmentInfoModal] = useState<boolean>(false); // Manage modal visibility
  const [currentEnrichment, setCurrentEnrichment] = useState<EnrichmentDetails | null>(null);
  const [enrichments, setEnrichments] = useState<EnrichmentDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const pdfPage = useRef<HTMLDivElement | null>(null);
  const pdfContainerViewerRef = useRef<HTMLDivElement | null>(null);
  const pdfSidebarRef = useRef<HTMLDivElement | null>(null);
  const showEnrichmentInfoModalRef = useRef(showEnrichmentInfoModal); // Ref to track the latest value
  const lastSelection = useRef<{ text: string; range: Range | null }>({ text: '', range: null });

  const handleAccessKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkAccessKey();
    }
  };

  const checkAccessKey = async () => {
    try {
      const { apiConfig, apiParams } = await apiService.getApiConfig();
      const api = DefaultApiFactory(apiConfig); // Get the API instance
      const accessKeyData = { key: accessKey };
      const response = await api.checkAccess(accessKeyData, apiParams);
      if (response.data) {
        setHasAccess(true);
      }
      else {
        setHasAccess(false);
        alert('Invalid access key');
      }
    }
    catch (err) {
      console.error('Failed to check access key:', err);
      alert('Failed to check access key');
    }
  }

  const handleRefreshClick = async () => {
    setServerVersion(await apiService.getServerVersion());
    await fetchBooks(null);
  };

  React.useEffect(() => {
    apiService.getServerVersion().then((version) => {
      setServerVersion(version);
    });
  }, []);

  // Listen for authentication state changes
  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        currentUser.getIdToken().then((idToken) => apiService.setToken(idToken));
        setProfilePicture(currentUser.photoURL); // Set profile picture from Firebase
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch books when the user is authenticated
  React.useEffect(() => {
    fetchBooks(null);
  }, [user]); // Re-run when `user` state changes

  React.useEffect(() => {
    alignSidebarHeight();
  }, [pdfPageWidth, currentScale, numPages]);

  React.useEffect(() => {
    if (pdfContainerViewerRef.current) {
      // Set initial page width
      updatePdfPageWidth(pdfContainerViewerRef.current.clientWidth);

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentBoxSize) {
            updatePdfPageWidth(entry.contentRect.width);
          }
        }
      });

      resizeObserver.observe(pdfContainerViewerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [pdfContainerViewerRef.current]);

  React.useEffect(() => {
    if (pdfContainerViewerRef.current) {
      updatePdfPageWidth(pdfContainerViewerRef.current.clientWidth);
    }
    const handleResize = () => {
      if (pdfContainerViewerRef.current) {
        const oldPageWidth = pdfPageWidth;
        if (oldPageWidth !== pdfContainerViewerRef.current.clientWidth) {
          updatePdfPageWidth(pdfContainerViewerRef.current.clientWidth);
        }
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [pdfFile]);

  React.useEffect(() => {
    fetchEnrichments();
  }, [currentBook]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      resetCurrentBook();
      setUser(null);
    }
    catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleAddBook = () => {
    setModifyBook({
      title: '',
      authors: '',
      description: '',
      type: 'paper',
    });
    setShowBookInfoModal(true); // Show the modal when the add book button is clicked
  };

  const handleSaveBook = async () => {
    if (!modifyBook) {
      console.error('No book data found');
      return;
    }
    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorBookApiFactory(apiConfig); // Get the API instance
    if (!modifyBook.id) {
      const bookId = await api.createBook(modifyBook, apiParams);
      if (bookId.data) {
        modifyBook.id = bookId.data;
      }
    }
    else {
      await api.updateBook(modifyBook.id, modifyBook, apiParams);
    }

    await fetchBooks(modifyBook); // Fetch books again to update the list

    // Close the modal after saving the book
    setShowBookInfoModal(false);
    setModifyBook(null);
  };
  
  const handleCancelBook = () => {
    setShowBookInfoModal(false); // Close the modal without saving
    setModifyBook(null);
  };

  const processContentArray = async (contentsArray: ArrayBuffer) => {
    setBookContentsArray(contentsArray);
    setBookContents(new Blob([contentsArray], { type: 'application/pdf' }));
    await updateCurrentPage(0); // Reset page to first page upon upload
    // Load the PDF document
    const pdf = await pdfjs.getDocument(contentsArray).promise; // Load the PDF document
    if (pdf) {
      setPdfDocument(pdf);
    }
  }

  const handleBookClick = async (book: BookDetails) => {
    if (book.id && book.id !== currentBook?.id) {
      setCurrentBook(book);

      setLoading(true); // Show loading overlay
      try {
        const { apiConfig, apiParams } = await apiService.getApiConfig('arraybuffer');
        const api = AuthorBookApiFactory(apiConfig); // Get the API instance
        const contents = await api.downloadBookContents(book.id, apiParams);
        const contentsData = contents as unknown as { data: ArrayBuffer };
        if (contentsData.data && contentsData.data.byteLength > 0) {
          await processContentArray(contentsData.data);
        }
        else {
          // currentBook must remain the same, hence NOT calling resetCurrentBook()
          setPdfDocument(null);
          resetEnrichments();
          setBookContents(null);
        }
      }
      catch (err) {
        resetCurrentBook();
        console.error('Failed to fetch book contents:', err);
      }
      setLoading(false); // Hide loading overlay
    }
    else {
      resetCurrentBook();
      console.error('Failed to find book selection');
    }
  };  

  const handleBookEditClick = () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }
    setModifyBook({ ...currentBook });
    setShowBookInfoModal(true);
  };

  const handleBookDeleteClick = async () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }
    setShowBookDeleteModal(true);
  }

  const handleDeleteBookConfirm = async () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book ID found');
      return;
    }
    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorBookApiFactory(apiConfig); // Get the API instance
    await api.deleteBook(currentBook.id, apiParams);

    await fetchBooks(null); // Fetch books again to update the list

    setShowBookDeleteModal(false);
    resetCurrentBook();
  };

  const handleDeleteBookCancel = () => {
    setShowBookDeleteModal(false);
  };

  const handleGeneratePDFClick = async () => {
    await generatePDF();
  }

  const handleUploadContents = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      console.error('No file selected');
      return;
    }
    if (!currentBook?.id) {
      console.error('No book selected');
      return;
    }

    setLoading(true); // Show loading overlay

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorBookApiFactory(apiConfig); // Get the API instance
    const result = await api.uploadBookContents(currentBook.id, file, apiParams);
    if (!result.data) {
      console.error('Failed to upload contents');
    }

    // Read the file contents (you can use FileReader to do this)
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target?.result;
      if (content) {
        await processContentArray(content as ArrayBuffer);
      }
    };
    
    reader.onerror = () => {
      console.error('Failed to read file');
    };

    reader.readAsArrayBuffer(file);
    setPdfFile(file);

    setLoading(false); // Hide loading overlay
  };

  const onDocumentLoadSuccess = () => {
    setNumPages(pdfDocument?.numPages!);
  };

  const onDocumentLoadError = (error: any) => {
    console.error('Error while loading the document:', error);
  };

  const handleNextPage = async () => {
    if (numPages && currentPage < (numPages - 1)) {
      await updateCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = async () => {
    if (currentPage > 0) {
      await updateCurrentPage(currentPage - 1);
    }
  };

  const fetchBooks = async (newCurrentBook: BookDetails | null) => {
    if (user) {
      try {
        setBooksLoading(true);
        setError(null); // Reset error state

        const { apiConfig, apiParams } = await apiService.getApiConfig();
        const api = AuthorBookApiFactory(apiConfig); // Get the API instance
        const response = await api.getAllBooks(apiParams);
        
        // Assuming the response contains books data
        const transformedBooks: BookDetails[] = response.data.map((book: PartialBookDetails) => ({
          id: book.id ?? '',
          title: book.title ?? '',
          authors: book.authors ?? '',
          description: book.description ?? '',
          type: book.type ?? 'paper',
        }));
        setBooks(transformedBooks); // Set the books from the transformed response

        if (newCurrentBook) {
          setCurrentBook({ ...newCurrentBook });
        }
        else {
          setCurrentBook(null);
        }
        setPdfDocument(null);
        resetEnrichments();
      }
      catch (err) {
        setError('Failed to fetch books.'); // Handle any errors
      }
      finally {
        setBooksLoading(false);
      }
    }
  };

  const fetchEnrichments = async () => {
    if (!currentBook || !currentBook.id) {
      console.error('No book selected');
      return;
    }

    const { apiConfig, apiParams } = await apiService.getApiConfig();
    const api = AuthorEnrichmentApiFactory(apiConfig); // Get the API instance
    const fetchedEnrichmenets = await api.getEnrichmentBaseList(currentBook.id, undefined, apiParams);
    if (fetchedEnrichmenets.data) {
      setEnrichments(fetchedEnrichmenets.data);
    }
  };

  const resetEnrichments = () => {
    setEnrichments([]);
  };

  const resetCurrentBook = () => {
    setPdfDocument(null);
    resetEnrichments();
    setBookContents(null);
    setCurrentBook(null);
  };

  const updateCurrentPage = async (newPage: number) => {
    setCurrentPage(newPage);
    alignSidebarHeight();
  };

  const updatePdfPageWidth = (newWidth: number) => {
    const adjustedWidth = newWidth > 300 ? newWidth : 300;
    setPdfPageWidth(adjustedWidth - 150);
  };

  const updateScale = (newScale: number) => {
    setCurrentScale(newScale); // Update the current scale state
  };

  const alignSidebarHeight = () => {
    // Update sidebar height based on the height of the pdf container
    if (pdfPage.current && pdfSidebarRef.current) {
      pdfSidebarRef.current.style.height = `${pdfPage.current.clientHeight}px`;
    }
  };

  const onPageRenderSuccess = () => {
    alignSidebarHeight();
  };

  const handleQRCodeClick = (enrichment: EnrichmentDetails) => {
    if (!enrichment.id) {
      console.error('Enrichment ID not found');
      return;
    }

    console.log(`QR Code clicked for enrichment ID: ${enrichment.id}`);
    if (pdfSidebarRef.current) {
      // saving selected text
      const selection = window.getSelection();  
      if (selection && selection.toString().trim()) {
        setSelectedText(selection.toString());
      }
      setCurrentEnrichment(enrichment);
      setShowEnrichmentInfoModal(true);
    }
  };

  const handleNewEnrichmentClick = (yOffsetPercent: number) => {
    if (!currentBook || !currentBook.id) {
      console.error('No book selected');
      return;
    }

    console.log(`Clicked outside QR codes at offset: ${yOffsetPercent}%`);
    if (pdfSidebarRef.current) {
      // saving selected text
      const selection = window.getSelection();  
      if (selection && selection.toString().trim()) {
        setSelectedText(selection.toString());
      }      
      setCurrentEnrichment({
        book: currentBook.id,
        type: 'ai',
        page: currentPage,
        offset: yOffsetPercent,
      });
      setShowEnrichmentInfoModal(true);
    }
  };

  const handleSidebarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Ignore clicks in the "sterile" zone around QR codes
    const sterileZoneSize = 10; // Adjust sterile zone size as needed
    const qrCodes = document.querySelectorAll('.enrichment-indicator');
    let clickedInsideQRCode = false;

    qrCodes.forEach((qrCode) => {
      const rect = qrCode.getBoundingClientRect();
      if (
        e.clientX > rect.left - sterileZoneSize &&
        e.clientX < rect.right + sterileZoneSize &&
        e.clientY > rect.top - sterileZoneSize &&
        e.clientY < rect.bottom + sterileZoneSize
      ) {
        clickedInsideQRCode = true;
      }
    });

    if (!clickedInsideQRCode) {
      const sidebarRect = e.currentTarget.getBoundingClientRect();
      const yOffsetPercent = ((e.clientY - sidebarRect.top) / sidebarRect.height) * 100;
      handleNewEnrichmentClick(yOffsetPercent);
    }
  };

  const closeEnrichmentDialog = async (refreshEnrichments: boolean) => {
    setCurrentEnrichment(null);
    setSelectedText('');
    setShowEnrichmentInfoModal(false); // Close the modal

    if (refreshEnrichments) {
      await fetchEnrichments();
    }
  };

  const generateQRCodeValue = (enrichment: EnrichmentDetails) => {
    if (!enrichment.id) {
      console.error('Enrichment ID not found');
      return '';
    }
    return `${apiService.getBackendBaseUrl()}/api/v1/enrichment/enrichment/${enrichment.book}/${enrichment.id}`;
  };

  const generatePDF = async () => {
    if (!pdfDocument) {
      console.error('No PDF document available');
      return;
    }

    setLoading(true); // Show loading overlay

    const pdfData = await pdfDocument.getData();
    const pdfLibDoc = await PDFDocument.load(pdfData.buffer.slice(0)); // Create a copy to prevent detached ArrayBuffer error

    // TODO: optimize based on fetchEnrichments() returning enrichments sorted by page
    const pages = pdfLibDoc.getPages();
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const { height } = page.getSize();

      // Set up the page and default text styles
      // const helveticaFont = await pdfLibDoc.embedFont(StandardFonts.Helvetica);
      // page.setFont(helveticaFont);
      // page.setFontSize(12);

      // Iterate over enrichments to add QR codes
      for (const enrichment of enrichments) {
        if (!enrichment.id) {
          console.error('Enrichment ID not found');
          continue;
        }
        if (enrichment.page !== i) {
          continue;
        }

        // Generate QR code as a data URL
        const qrCodeValue = generateQRCodeValue(enrichment);
        const qrCodeImageDataUrl: string = await new Promise((resolve, reject) => {
          QRCode.toDataURL(qrCodeValue, { errorCorrectionLevel: 'H' }, (err, url) => {
            if (err) reject(err);
            else resolve(url);
          });
        });

        const response = await fetch(qrCodeImageDataUrl);
        const qrCodeImageBytes = await response.arrayBuffer();

        // Embed the QR code image
        const qrImage = await pdfLibDoc.embedPng(qrCodeImageBytes);
        const qrImageDims = qrImage.scale(0.25); // Adjust scaling as needed

        // Calculate position based on enrichment offset
        const yPosition = height - (enrichment.offset / 100) * height;

        // Draw the QR code on the PDF page
        page.drawImage(qrImage, {
          x: 10, // Fixed X position for all QR codes; can be adjusted as needed
          y: yPosition - qrImageDims.height,
          width: qrImageDims.width,
          height: qrImageDims.height,
        });
      }
    }

    // Serialize the PDF to bytes and trigger a download
    const pdfBytes = await pdfLibDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'enrichedbook.pdf';
    link.click();

    setLoading(false); // Hide loading overlay
  };

  React.useEffect(() => {
    // Update the ref whenever showEnrichmentInfoModal changes
    showEnrichmentInfoModalRef.current = showEnrichmentInfoModal;
  }, [showEnrichmentInfoModal]);
  
  const handleSelectionChange = () => {
    const selection = window.getSelection();  
    if (selection && selection.toString().trim()) {
      // Store the selected text and range
      lastSelection.current = {
        text: selection.toString(),
        range: selection.getRangeAt(0),
      };
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    const selection = window.getSelection();
    console.log('selection: ', selection);
    if (lastSelection.current.range) {
      if (showEnrichmentInfoModalRef.current) {
        // Prevent selection clearing if the modal is open
        selection?.removeAllRanges();
        selection?.addRange(lastSelection.current.range);
      }
      else if (pdfSidebarRef.current && pdfSidebarRef.current.contains(e.target as Node)) {
        // Prevent selection clearing only if the click is within pdfSidebar
        e.preventDefault(); // Prevents the default action that clears selection
      }
      else {
        lastSelection.current = { text: '', range: null };
      }
    }
  };
  
  // Set up event listeners for selection management
  React.useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);
    document.addEventListener('mousedown', handleMouseDown);

    // Cleanup listeners when component unmounts
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);
  
  const testAIQuery = () => {
    // Placeholder for AI query testing
    alert('AI query testing is not implemented yet');
  };

  if (!hasAccess) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <h2>Restricted Access</h2>
        <input
          type="password"
          placeholder="Enter Access Key"
          value={accessKey}
          onChange={(e) => { setAccessKey(e.target.value); }}
          onKeyDown={handleAccessKeyPress}
        />
        <button onClick={checkAccessKey}>Submit</button>
      </div>
    );
  }

  return (
    <div className="app-container">
      {/* Drawer-style Sidebar */}
      <div className="drawer">
        <div className="version-info">
          <h5 className="project-server-version">BE: {serverVersion}</h5>
          <h5 className="project-frontend-version">FE: {getFrontendVersionString()}</h5>
          <button className="refresh-button" onClick={handleRefreshClick}>
            &#x21bb;
          </button>
        </div>
        <div className="logo-section">
          <img src="/logo.webp" alt="Enriched Books Logo" className="project-logo" />
          <h2 className="project-name">Enriched Books</h2>
        </div>

        {/* Account Section */}
        <div className="account-section section">
          {user ? (
            <>
              {profilePicture ? (
                <img src={profilePicture} alt="Profile" className="profile-picture" />
              ) : (
                <div className="default-avatar">
                  {user.displayName
                  ? user.displayName.split(' ').splice(0, 2).map(name => name.charAt(0)).join('') // Extract initials from displayName
                  : 'A'} {/* Fallback to 'A' if displayName is not available */}
                </div>
              )}
              <button className="auth-button" onClick={handleSignOut}>Sign Out</button>
            </>
          ) : (
            <>
              <p>Sign in to your account:</p>
              <FirebaseAuth />
            </>
          )}
        </div>

        {/* Section for Book List */}
        {user ? (
          <div className="books-section section">
            <div className="books-header">
              <h3 className="books-title">Your Books</h3>
              <button className="add-book-button" onClick={handleAddBook}>+</button>
            </div>
            {booksLoading ? (
              <p>Loading books...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <ul className="books-list">
                {books.map((book) => (
                  <li
                    key={book.id}
                    className={`book-item ${currentBook && currentBook.id === book.id ? 'current' : ''}`}
                    onClick={() => handleBookClick(book)}
                  >
                    <div className="book-title">{book.title}</div>
                    <div className="book-authors">{book.authors}</div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : null}
      </div>

      {/* Main Content Area */}
      <div className="content">
        {currentBook ? (
          <>
            <div className="book-content-title">
              <h3>{currentBook.title}</h3>
              <div className="title-bar-buttons">
                <button onClick={handleBookEditClick}>Edit Title...</button>
                <button onClick={handleGeneratePDFClick} disabled={bookContents == null}>Generate PDF</button>
                <button onClick={handleBookDeleteClick}>Delete...</button>
              </div>
            </div>  
            {bookContents ? (
              <>
                <div className="book-editor">
                  <div className="pdf-viewer" ref={viewerRef}>
                    <div className="pdf-container" ref={pdfContainerViewerRef}>
                      <div className="pdf-sidebar" onClick={handleSidebarClick} ref={pdfSidebarRef}>
                        {enrichments.map((enrichment) => (
                          (enrichment.page === currentPage &&
                            <div
                              key={enrichment.id}
                              className="enrichment-indicator"
                              style={{ top: `${enrichment.offset}%`, left: '50%', transform: 'translate(-50%, -50%)' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (enrichment.id) {
                                  handleQRCodeClick(enrichment);
                                }
                              }}
                            >
                              {enrichment.id && <QRCodeCanvas value={generateQRCodeValue(enrichment)} size={50} />}
                            </div>
                          )
                        ))}
                      </div>
                      <Document file={bookContents} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                        {pdfPageWidth && (
                          <Page
                            pageNumber={currentPage + 1}
                            width={pdfPageWidth}
                            renderTextLayer={true}
                            renderAnnotationLayer={true}
                            scale={currentScale}
                            onRenderSuccess={onPageRenderSuccess}
                            inputRef={pdfPage}
                          />
                        )}
                      </Document>
                    </div>
                    {numPages && (
                      <div className="pdf-controls">
                        <div className="page-controls">
                          <button onClick={handlePreviousPage} disabled={currentPage === 0}>
                            Previous Page
                          </button>
                          <span>
                            Page {currentPage + 1} of {numPages}
                          </span>
                          <button onClick={handleNextPage} disabled={currentPage === (numPages - 1)}>
                            Next Page
                          </button>
                        </div>
                        <div className="page-controls">
                          <button onClick={() => updateScale(currentScale - 0.2)} disabled={currentScale <= 0.3}>-</button>
                          <span>{Math.round(currentScale * 100)}%</span>
                          <button onClick={() => updateScale(currentScale + 0.2)} disabled={currentScale >= 1.0}>+</button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <p>Contents have not been uploaded for this book.</p>
                <div className="upload-contents-container">
                  <input
                    type="file"
                    id="upload-contents"
                    style={{ display: 'none' }}
                    accept=".txt,.doc,.pdf,.epub"
                    onChange={handleUploadContents}
                  />
                  <button onClick={() => document.getElementById('upload-contents')?.click()} className="upload-contents-style-button">
                    Upload Book Contents
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <h1 className="welcome-message">Welcome to Enriched Books</h1>
            {user ? (
              <>
                <h2 className="welcome-message-small">Welcome, {user.displayName}!</h2>
                <h2 className="welcome-message-smaller">Please select a book on the left side of the page</h2>
                <h2 className="welcome-message-smaller">or click the "+" button to create a new book.</h2>
              </>
            ) : (
              <p className="welcome-message-small">Please sign in to access your account.</p>
            )}
          </>
        )}

        {/* Modal for Adding Book */}
        {showBookInfoModal && modifyBook && (
          <div className="edit-book-modal-overlay">
            <div className="edit-book-modal">
              <h3>Edit Book Details</h3>
              <input
                type="text"
                placeholder="Book Title"
                value={modifyBook.title}
                onChange={(e) => {
                  modifyBook.title = e.target.value;
                  setModifyBook({ ...modifyBook });
                }}
              />
              <input
                type="text"
                placeholder="Book Authors"
                value={modifyBook.authors}
                onChange={(e) => {
                  modifyBook.authors = e.target.value;
                  setModifyBook({ ...modifyBook });
                }}
              />
              <select
                value={modifyBook.type}
                onChange={(e) => {
                  modifyBook.type = e.target.value;
                  setModifyBook({ ...modifyBook });
                }}
              >
                <option value="paper">Paper</option>
                <option value="digital">Digital</option>
              </select>
              <div className="edit-book-modal-buttons">
                <button onClick={handleSaveBook}>Save</button>
                <button onClick={handleCancelBook}>Cancel</button>
              </div>
            </div>
          </div>
        )}
  
        {/* Modal for Adding Book */}
        {showBookDeleteModal && (
          <div className="delete-book-modal-overlay">
            <div className="delete-book-modal">
              <h3>Delete book "{currentBook?.title}"?</h3>
              <div className="delete-book-modal-buttons">
                <button onClick={handleDeleteBookConfirm}>Delete</button>
                <button onClick={handleDeleteBookCancel}>Cancel</button>
              </div>
            </div>
          </div>
        )}
  
        {/* Modal for Enrichment Information */}
        {(showEnrichmentInfoModal && currentEnrichment) && (
          <EnrichmentDialog
            enrichmentBase={currentEnrichment}
            selectedText={selectedText}
            onClose={closeEnrichmentDialog}
          />
        )}
      </div>
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default App;
